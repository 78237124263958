import SvgPathOutline from '@/components/templateux/svg/svg-path-outline'

export default function SvgJsx({
        type='outline'
        ,icon='home'
        , className=''
        , title=''
        , color='currentColor'
        , onClick=null 
        , strokeWidth='2'
    }) {

    var varTitle = title || icon


    if (type=='outline') {
        return (
            <svg 
                role="img"
                //aria-labelledby={id}
                onClick={onClick}
                className={className} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke={color}>
                <title 
                //id={id}
                >{varTitle}</title>
                <SvgPathOutline 
                    svg={icon} 
                    strokeWidth={strokeWidth}
                />
            </svg>
             )
    } else { 
        //fill
        return (
            <svg
                role="img"
                //aria-labelledby={id}
                onClick={onClick}
                className={className}  xmlns="http://www.w3.org/2000/svg" fill={color} viewBox="0 0 20 20" stroke="none">
                 <title 
                 //id={id}
                 >{varTitle}</title>
                <SvgPathOutline 
                    svg={icon} 
                    strokeWidth={strokeWidth}
                />
            </svg>
             )
    }

}

