import { hasData } from "./hasData";

export function truncate(input,vlength=25,appendage='...') {
   if (hasData(input)) {
      if (input?.length > vlength) {
         return `${input.substring(0, vlength)}${appendage}`;
      }
      return input;
   }
   return appendage;
};