
import { createContext, useContext, useEffect, useState } from 'react';
import Ably from "ably/promises";

export const PushContext = createContext(null);

export const PushProvider = ({ children }) => {
  const [ably, setAbly] = useState(null)
  

  useEffect(() => {
    //console.log('PushProvider init');

    setAbly(() => {
      const ably = new Ably.Realtime({ 
        authUrl: '/api/private/websockets/ably/createTokenRequest' 
          ,recover: (lastConnectionDetails:any, cb) => {
              /* Only recover if the current path hasn't changed, start a */
              if (lastConnectionDetails.location.href === document.location.href) {
                cb(true); /* recover connection */
              } else {
                cb(false); /* do not recover connection */
              }
        }
      });

      ably.connect();

      return ably;
    });

    return () => {
      //console.log('PushProvider cleanup');
      if (ably) ably?.close();
    };
  }, []);

  

  return (
    <PushContext.Provider value={ably}>
      {children}
    </PushContext.Provider>
  );
};

export const usePushContext = () => useContext(PushContext);
