
import { createContext, useState } from 'react';

export const ModalContext = createContext(null);

function ModalWrapperNested({
                              children, pageProps
                            }) {

  const [isOpen,set_isOpen] = useState('')
  

  return (
    
    <ModalContext.Provider value={{ isOpen, set_isOpen }}>
      {children}
    </ModalContext.Provider>
  );
}

export const ModalWrapper = ModalWrapperNested

