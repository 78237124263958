import { ConfirmContext } from "@/components/context/confirm-context";
import { useContext, useEffect, useState } from "react";
import { hasData } from "../hasData";




//From this tutorial: 
//https://medium.com/@jaredloson/a-replacement-for-window-confirm-using-promises-and-react-hooks-cfc011e76a7a

export const useConfirm = () => {

  const confirm_defaults = {
      title: 'Are you sure?',
      body: <></>,
      confirm: 'OK',
      cancel: 'Cancel',
      show_title: true,
      show_confirm: true,
      show_cancel: true
  }


  const [confirm, setConfirm] = useContext(ConfirmContext);
  const [needsCleanup, setNeedsCleanup] = useState(false);
  
  const isConfirmed = (prompt) => {
    setNeedsCleanup(true);

    //console.log("prompt",prompt,(typeof prompt === 'string' || prompt instanceof String))

    const promise = new Promise((resolve, reject) => {

      setConfirm({
        prompt: (typeof prompt === 'string' || prompt instanceof String) 
                ? {
                    title: hasData(prompt) ? prompt : confirm_defaults.title,
                    body: confirm_defaults.body,
                    confirm: confirm_defaults.confirm,
                    cancel: confirm_defaults.cancel,
                    show_title: confirm_defaults.show_title,
                    show_confirm: confirm_defaults.show_confirm,
                    show_cancel: confirm_defaults.show_cancel
                }
                : {
                        title: hasData(prompt?.title) ? prompt?.title : confirm_defaults.title,
                        body: hasData(prompt?.body) ?  prompt?.body : confirm_defaults.body,
                        confirm: hasData(prompt?.confirm) ?  prompt?.confirm : confirm_defaults.confirm,
                        cancel: hasData(prompt?.cancel) ?  prompt?.cancel : confirm_defaults.cancel,
                        show_title: hasData(prompt?.show_title) ?  prompt?.show_title : confirm_defaults.show_title,
                        show_confirm: hasData(prompt?.show_confirm) ?  prompt?.show_confirm : confirm_defaults.show_confirm,
                        show_cancel: hasData(prompt?.show_cancel) ?  prompt?.show_cancel : confirm_defaults.show_cancel
                    },
        isConfirmOpen: true,
        proceed: resolve,
        cancel: reject
      });
    });
    return promise.then(
      () => {
        setConfirm({ ...confirm, isConfirmOpen: false });
        return true;
      },
      () => {
        setConfirm({ ...confirm, isConfirmOpen: false });
        return false;
      }
    );
  };

  useEffect(() => {
    return () => {
      if (confirm.cancel && needsCleanup) {
        confirm.cancel();
      }
    };
  }, [confirm, needsCleanup]);

  return {
    ...confirm,
    isConfirmed
  };
};